<template>
  <div class="maincontent">
  
  </div>
</template>

<script>
import { getSession } from "@/utils/utils.js";
export default {
  name: "order",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.maincontent {
  font-size: 18px;
  height: 100%;
  .title {
    text-align: left;
    height: 40px;
    line-height: 40px;
    margin-left:50px ;
  }
}
</style>
