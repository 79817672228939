<template>
  <div id="ProBuy">
    <div class="ProBuy_in">
      <div class="resetpwd_top">
        <img
          src="~/assets/images/nav/logo.png"
          alt="找不到资源"
          @click="toindex"
        />
        <span class="gang">|</span>
        <h2>账户管理</h2>
      </div>
      <div class="main">
        <div class="order">
          <el-tabs tab-position="left" style="height: 200px">
            <el-tab-pane label="修改密码"> 
                <changepwd></changepwd>
            </el-tab-pane>
            <el-tab-pane label="积分管理"> </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import footerPage from "@/components/common/footer/footerPage.vue";
import changepwd from "./changepwd.vue"
import { getSession } from "@/utils/utils.js";
export default {
  name: "order",
  data() {
    return {
      tabarr: [],
      activeName: "all",
      readynum: 0,
      imgUrlFront: window.sessionStorage.getItem("apiImgUrl"), //图片前缀
      orderList: [], //订单页
      total: 0, //总数
      currentPage: 1,
      pageSize: 3,
    };
  },
  components: {
    footerPage,
    changepwd
  },
  created() {
    this.getordertab();
  },
  methods: {
    //根据status对应订单状态
    getordername(status) {
      switch (status) {
        case 0:
          return "未支付";
          break;
        case 1:
          return "进行中";
          break;
        case 2:
          return "审核中";
          break;
        case 3:
          return "进行中";
          break;
        case 4:
          return "正在办理";
          break;
        case 5:
          return "办理完成";
          break;
        case 6:
          return "已完成";
          break;
        case 7:
          return "已完成";
          break;
        case 8:
          return "已取消";
          break;
        case 9:
          return "未通过";
          break;
      }
    },
    //获取订单类型
    getordertab() {
      this.$axios("API/GetDict", {
        Version: "",
        DictTypeID: "DDXX_DDZT",
      }).then((res) => {
        this.tabarr = [{ DictName: "全部", DictID: "all" }];
        //全部订单
        let request = {
          Version: "",
          OrderUserGuid: this.$store.state.userinfo.UserGuid,
          OrderNumber: "",
          OrderStartTime: "",
          OrderEndTime: "",
          Status: "",
          PageIndex: this.currentPage,
          PageSize: this.pageSize,
          JZToken: this.$store.state.userinfo.TokenCode,
          UserGuid: this.$store.state.userinfo.UserGuid,
        };
        this.$axios("/API/Get_Order", request).then((res) => {
          this.total = res.count;
          this.orderList = res.data;
        });
        res.data.forEach((item) => {
          this.tabarr.push(item);
          if (item.DictName == "待支付") {
            let request = {
              Version: "",
              OrderUserGuid: this.$store.state.userinfo.UserGuid,
              OrderNumber: "",
              OrderStartTime: "",
              OrderEndTime: "",
              Status: item.DictID,
              PageIndex: this.currentPage,
              PageSize: this.pageSize,
              JZToken: this.$store.state.userinfo.TokenCode,
              UserGuid: this.$store.state.userinfo.UserGuid,
            };
            this.$axios("/API/Get_Order", request).then((res) => {
              this.readynum = res.count;
            });
          }
        });
      });
    },
    //获取订单
    getorder(dictid, pageindex) {
      let request = {
        Version: "",
        OrderUserGuid: this.$store.state.userinfo.UserGuid,
        OrderNumber: "",
        OrderStartTime: "",
        OrderEndTime: "",
        Status: dictid,
        PageIndex: pageindex,
        PageSize: this.pageSize,
        JZToken: this.$store.state.userinfo.TokenCode,
        UserGuid: this.$store.state.userinfo.UserGuid,
      };
      this.$axios("/API/Get_Order", request).then((res) => {
        this.total = res.count;
        this.orderList = res.data;
      });
    },

    //上一页
    prevclick() {
      this.currentPage -= 1;
      this.getorder(
        this.activeName == "all" ? "" : this.activeName,
        this.currentPage
      );
    },
    //下一页
    nextclick() {
      this.currentPage += 1;
      this.getorder(
        this.activeName == "all" ? "" : this.activeName,
        this.currentPage
      );
    },
    //页码改变，当前页
    currentclick(e) {
      this.currentPage = e;
      this.getorder(
        this.activeName == "all" ? "" : this.activeName,
        this.currentPage
      );
    },
    //获取不同类型订单数量
    handleClick(e) {
      this.activeName == e.name;
      this.getorder(this.activeName == "all" ? "" : this.activeName, 1);
    },
    toindex() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss">
@import "userinfo.scss";
</style>
